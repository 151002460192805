import React from 'react';
import face from './facealone.png';
import downArrow from './down-arrow.png';
import album from './FLORA & FAUNA EP COVER.jpg';
import YouTubeSubscribe from "./components/youtubeSubscribe";
import shopify from "./shopify.png";
import './App.css';
import $ from 'jquery';

const windowWidth = $(window).width();
const windowHeight = $(window).height();
var spotifyHeight = 420;

if (windowWidth < 1400 && windowWidth > 720) {
  spotifyHeight = 152;
} else if (windowWidth < 721) {
  spotifyHeight = windowHeight;
}

class App extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  render() {
    // const { loading } = this.state;
    let channelid = "UCjgJwBDfnUhBFrrau9ZKOhg";

    function scrollToBottom() {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }

    // function scrollToTop() {
    //   window.scrollTo({ top: 0, behavior: 'smooth' })
    // }

    // if (loading) { // if your component doesn't have to wait for async data, remove this block 
    //   return (
    //     <div id="loadingDiv">
    //       <div class="sk-cube-grid">
    //         <div class="sk-cube sk-cube1"></div>
    //         <div class="sk-cube sk-cube2"></div>
    //         <div class="sk-cube sk-cube3"></div>
    //         <div class="sk-cube sk-cube4"></div>
    //         <div class="sk-cube sk-cube5"></div>
    //         <div class="sk-cube sk-cube6"></div>
    //         <div class="sk-cube sk-cube7"></div>
    //         <div class="sk-cube sk-cube8"></div>
    //         <div class="sk-cube sk-cube9"></div>
    //       </div>

    //       <div class="loadingText">
    //         loading...
    //       </div>
    //     </div>
    //   )
    // }

    return (
      <div className="App">
        <nav>
          <h1>some Human</h1>
        </nav>
        <div className="App-header">
          <img className='mobileAlbum' src={album} alt='Album Cover' hidden/>
          <div className="link-div topItem">
            <a className="App-link" href="https://open.spotify.com/artist/4CpMwYc9T9FA8AgRQxYTsw" target="_blank" rel="noopener noreferrer"> Spotify </a>
          </div>
          <div className="center-content">
            <div className="col-left">
              <div className="link-div link-side">
                <a className="App-link" href="https://www.instagram.com/somehumansounds/" target="_blank" rel="noopener noreferrer"> Instagram </a>
              </div>
              <div className="link-div link-side">
                <a className="App-link" href="https://www.tiktok.com/@somehumansounds" target="_blank" rel="noopener noreferrer"> TikTok </a>
              </div>
            </div>
            <div className="col-icon">
              <img src={face} className="App-logo" alt="logo" />
            </div>
            <div className="col-right">
              <div className="link-div link-side">
                <a className="App-link" href="https://www.youtube.com/channel/UCjgJwBDfnUhBFrrau9ZKOhg/featured" target="_blank" rel="noopener noreferrer"> YouTube </a>
              </div>
              <div className="link-div link-side">
                <a className="App-link" href="https://music.apple.com/us/artist/some-human/1606537390" target="_blank" rel="noopener noreferrer"> Apple </a>
              </div>
            </div>
          </div>
          <div className="link-div">
            <a className="App-link" href="https://somehuman.bandcamp.com/" target="_blank" rel="noopener noreferrer"> Bandcamp </a>
          </div>
        </div>
        <div className='subscribeContainer'>
        <div className='subscribeLabel'>follow </div>
          <YouTubeSubscribe
          // channelName={channelName}
          channelid={channelid}
          theme={"default"}
          layout={"default"}
          count={"hidden"}
        />
        <iframe scrolling="no" title='Band Camp Subscribe' className="bandcampButton" src="https://bandcamp.com/band_follow_button_classic/2508856617"></iframe>
        </div>
        <footer>
          {/* <div className='subscribeLabel contactLabel'>contact</div> <a className='contactLink' href="mailto: somehuman.media@gmail.com">somehuman.media@gmail.com</a> */}
          <a title='some Human Merch' className="App-link shopifyLink" href="https://somehuman.myshopify.com/" target="_blank" rel="noopener noreferrer">
            <img className='shopify' src={shopify} alt='Album Cover' />
          </a>
        </footer>
        <div className='spotifyContainer'>
          <iframe title='Spotify Player' src="https://open.spotify.com/embed/artist/4CpMwYc9T9FA8AgRQxYTsw?utm_source=generator&theme=0" width="100%" height={spotifyHeight} frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>
        <div className='musicButtonContainter bounce'>
          <button className='musicButton' onClick={scrollToBottom}>
            <div>
              listen  
            </div>
            <div className='arrow'><img src={downArrow} className="downarrow" alt="Scroll Down" /></div>
          </button>
        </div>
        {/* <div className='scrollupContainter bounce'>
          <button className='musicButton'  onClick={scrollToTop}>
          <div className='arrow'>⮙</div>
          </button>
        </div> */}
      </div>
    );
  }
}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 4000));
}

export default App;
